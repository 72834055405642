import React, {Component} from 'react';

class NotMobileView extends Component {
    render() {
        return (
            <div>
                {/*<p>{this.props.browserName}</p>*/}
                {/*<p>{this.props.browserVersion}</p>*/}
                <center style={{fontSize: "22px", fontWeight: "600", paddingTop: "34px"}}>কুইজ খেলতে হলে <a href="https://duronto.com.bd/" style={{color: "#dc1c2c"}}>দুরন্ত ব্রাউজার </a> ব্যবহার করুন।</center>
            </div>
        );
    }
}

export default NotMobileView;
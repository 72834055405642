import React, { Component } from 'react';
import axios from 'axios';

import '../css/header.css';
import '../css/home.css';
import '../css/footer.css';

import footerImg from '../img/powered-by.png';
import logo from '../img/logo.png';
import independentImg from '../img/independentday.png';

class PushIdPage extends Component {
    constructor(props){
        super(props)
    }

    componentDidMount() {
        //alert(localStorage.getItem('check_back'))
        if(localStorage.getItem('check_back') == this.props.match.params.push_id){
            window.location.href='/';
        }
        //count
        var config = {
            method: 'post',
            url: 'https://apiv1.duronto.com.bd/api/web/v1/push_impression/'+`${this.props.match.params.push_id}`,
            headers: { }
        };
          
          axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
          });
        //end_count
          
        //site_redirect
        var config = {
            method: 'post',
            url: 'https://apiv1.duronto.com.bd/api/web/v1/push_impression_details_by_push_id/'+`${this.props.match.params.push_id}`,
            headers: { }
        };
        localStorage.setItem('check_back', this.props.match.params.push_id);
        axios(config)
        .then(res => {
            if(res.status == 200){
                console.log(res.data.data.link)
                setTimeout(() => 
                    window.location.href=res.data.data.link
                , 10)
            } else {
                window.location.href='/';
            }
        })
        .catch(res => {
            window.location.href='/';
        })
        //end_site_redirect
    }

    render() {
        return (
            <div>
                {/* =================================log============================= */}
                    {/* <div className="dLogo">
                        <img src={logo} className="img-fluid" alt="Logo"/>
                    </div> */}
                {/* =================================end_logo========================= */}


                {/* =================================log============================= */}
                    {/* <div className="">
                        <img src={independentImg} className="img-fluid" alt="Logo"/>
                    </div> */}
                {/* =================================end_logo========================= */}


                {/* =================================footer=========================== */}
                {/* <div className="footerImg text-center">
                    <img src={footerImg} alt="robi" />
                </div> */}
                {/* =================================end footer======================= */}
            </div>
        );
    }
}

export default PushIdPage;
import React from 'react';

class Popup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            time: 'start',
            title: 'Duronto Quiz',
            text: 'Duronto <br /><br /> Browser' ,
            buttonText: 'Start the quiz'
        };

        this.popupHandle = this.popupHandle.bind(this);
    }

    popupHandle() {
        let { time } = this.state;
        let { score,total,rightAnswer } = this.props;

        window.location = "/";

        // if(total === rightAnswer){
        //    this.setState({
        //         time: 'end',
        //         title: 'Winner!',
        //         buttonText: 'Home Page'
        //     });
        // } else {
        //     this.setState({
        //         time: 'end',
        //         title: 'Congratulations!',
        //         buttonText: 'Restart'
        //     });
        // }

        // if(time === 'start'){
        //     this.setState({
        //         time: 'end',
        //         title: 'Congratulations!',
        //         buttonText: 'Restart'
        //     });
        //
        //     this.props.startQuiz();
        // } else {
        //     //location.reload();// restart the application
        // }
    }

    componentWillReceiveProps(nextProps) {

        let { score,total,rightAnswer } = this.props;

        console.log(total)
        console.log(rightAnswer);
        // alert(score)


        if(rightAnswer){

        } else{
            //alert(total == rightAnswer);
            if(total == rightAnswer){
                this.setState({
                    time: 'win',
                    title: 'অভিনন্দন!',
                    buttonText: 'হোম পৃষ্ঠা',
                    text: 'আপনি বিজয়ী. <br /> '
                })
            } else {
                this.setState({
                    time: 'restart',
                    title: 'Try again!',
                    buttonText: 'Restart',
                    text: 'আপনি কুইজটি শেষ করেন নি। <br /> ' +
                        'আপনি পেয়েছেন: <strong>' + this.props.score + '</strong> পয়েন্ট <strong>.'
                })
            }
        }


    }

    createMarkup(text) {
        return {__html: text};
    }


    render() {

        let { title, text, buttonText } = this.state;

        let { style } = this.props;

        return (
            <div className="popup-container" style={style}>
                <div className="container">
                    <div className="col-md-8 col-md-offset-2">
                        <div className="popup">
                            <h1>{title}</h1>
                            <p dangerouslySetInnerHTML={this.createMarkup(text)} />
                            <button className="fancy-btn" onClick={this.popupHandle}>{buttonText}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Popup

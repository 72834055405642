import React, {Component} from 'react';
import axios from "axios";
import '../../css/quiz.css';
import Loader from "react-loader-spinner";
import Main from "./Main";
import {Link, Redirect} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import nextclick from "../../sound/slider.wav";


class Data extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isNoQuestion: false
        }

        this.resendHome = this.resendHome.bind(this)
    }
    componentDidMount() {
        console.log("Category"+this.props.subjectId);
        var data = JSON.stringify({"id": this.props.subjectId});

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/quiz/category/v1/get_category_quiz',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            },
            data : data
        };

        axios(config)
            .then(response => {
                //console.log("Real:"+JSON.stringify(response.data));
                //decode
                const decodeData = Buffer.from(response.data, 'base64');
                const afterModify = JSON.parse(JSON.stringify(JSON.parse(decodeData).data));
                if(afterModify.length <= 0){
                    console.log("No Question In this Category.Try another type.");
                    this.setState({
                        isNoQuestion: true
                    })
                }
                this.setState({
                    items: afterModify
                })
                //console.log("Decode"+ JSON.stringify(afterModify));
            })
            .catch(function (error) {
                alert("No Question In this Category.Try another type."+error);
            });
    }

    resendHome(){
        new Audio(nextclick).play();
        //window.location.reload(false);
        window.location = '/quiz_home';
    }

    render() {
        if(!this.props.fromHomePage){
            return <Redirect to="hard_quiz" />
        }
        return (
            <div>

                {/*model*/}
                <Modal
                    show={this.state.isNoQuestion}
                    onHide={this.state.isNoQuestion}
                    centered='true'
                >
                    <Modal.Header>
                        <Modal.Title>
                            <p>এই বিষয়ে কোন প্রশ্ন নেই।</p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>
                        <Link onClick={this.resendHome}>
                            <i className="fa fa-home"></i>  আবার বিষয় নির্বাচন করেন
                        </Link>
                    </Modal.Body>
                </Modal>
                {/*end_model*/}

                { this.state.items.length > 0 ? <Main data={this.state.items}/> :
                    <div className="loader">
                        <Loader
                            type="Circles"
                            color="#dc1c2c"
                            height={50}
                            width={100}
                            timeout={1000} //3 secs
                        />
                    </div>
                }
            </div>
        );
    }
}

export default Data;
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import axios from "axios";
import ImgIcon from "./imgIcon";


import '../css/header.css';
import '../css/home.css';
import '../css/footer.css';


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function ScrollableTabsButtonAuto() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [all_links, setAll_links] = useState([]);
    const [singleData, setSingleData] = useState([]);

    useEffect(() => {
        var config = {
            method: 'post',
            url: 'https://apiv1.duronto.com.bd/api/web/v1/get_emergency_link',
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        axios(config)
            .then(res => {
                // console.log(res.data.data);
                setAll_links(res.data.data);
                setSingleData(res.data.data);
            })
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleQueryChange = (query) => {
        const data = JSON.stringify({"district": query});
        const config = {
            method: 'post',
            url: 'https://apiv1.duronto.com.bd/api/web/v1/search_by_location',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(res => {
                // console.log(res.data.data)
                setAll_links(res.data.data);
                setSingleData(res.data.data);
            })
    }


    return (
        <>
            <div className={classes.root}>
                <AppBar position="static" color="default" className={'tabHeader'}>
                    <Tabs
                        className={'tabSingleItem'}
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        {
                            all_links.map((item, i) =>
                                <Tab label={item.category_bn} {...a11yProps(i)} icon={<ImgIcon src={item.img}/>}
                                     key={i}></Tab>
                            )
                        }

                    </Tabs>
                </AppBar>

                {

                    singleData.map((item, i) =>
                        <TabPanel value={value} index={i}>

                            <div className="searchBox">
                                <input
                                    onChange={({target: {value}}) => handleQueryChange(value)}
                                 className={'form-control'} />
                                <div className="searchIcon">
                                    <i className="fa fa-search"></i>
                                </div>
                            </div>
                            <hr/>
                            <br/>
                            {
                                item.link.map((tabItem, i) =>
                                    <>
                                        <p>{tabItem.emergency_helpline_bn}
                                            <br/><small>{tabItem.phone_bn}</small></p>
                                        <hr/>
                                    </>
                                )
                            }
                        </TabPanel>
                    )
                }


            </div>
        </>
    );
}

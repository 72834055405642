import axios from "axios";

export default {
    getOTP: (msisdn) => {
        var data = JSON.stringify({"msisdn": msisdn });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/otp/send',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            },
            data : data
        };

        axios(config)
            .then(res => {
                if(res.data.success){
                    console.log("otp successfully sent")
                } else {
                    alert("OTP Not Sent.Try again!!")
                }
            })
    },
    // otherApiCall: (params) => {
    // }
}
import axios from "axios";

export default {
    updateWinner: (id) => {

        const data = JSON.stringify({
            "id": id,
            "quiz_type": "Know Bangladesh",
            "campaign_name": "Know Bangladesh"
        });

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/quiz/know/v1/update_winUser',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });

    },
    updateUserPoint: (msisdn,score) => {
        const data = JSON.stringify({
            "msisdn": msisdn,
            "quizpoints": score,
            "quiz_type": "Know Bangladesh",
            "campaign_name": "Know Bangladesh"
        });

        const config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/quiz/know/v1/update_point',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
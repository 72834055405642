import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

const FunctionalComponent = () => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmit = data => {
        alert(JSON.stringify(data));
    };
    return (
        <div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <label htmlFor='firstName'>First Name </label><br/>
                <input
                    type='text'
                    {...register('firstName', {
                        required: 'First name is required'
                    })}
                />
                {errors.firstName && <p>{errors.firstName.message}</p>}
                <br/><label>Password</label><br/>
                <input
                    type='password'
                    {...register('password', {
                        required: 'Password is required',
                        minLength: {
                            value: 4,
                            message: 'Password must be at least 4 characters'
                        }
                    })}
                />
                {errors.password && <p>{errors.password.message}</p>}
                <br/><label>Age</label><br/>
                <input type='text'
                    {
                        ...register('age',
                            {
                            required: 'Age Filed Must Be Required.'
                            }
                        )
                    }
                />
                {errors.age && <p>{errors.age.message}</p>}
                <br/>
                <button type='submit'>Submit</button>
            </form>
        </div>
    );
};

export default FunctionalComponent;
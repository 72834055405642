import React, {Component} from 'react';
import quiz_header_img from "../../img/header.png";
import unhappyImg from "../../img/un-happy.svg";
import answerClick from "../../sound/click.wav";
import click from "../../sound/click.wav";

class Answers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAnswered: false,
            //classNames: ['', '', '', '']
            preAnswer:'',
            setQstNo:'',
            setAnsType:''

        }

        this.checkAnswer = this.checkAnswer.bind(this);
        this.clearClasses = this.clearClasses.bind(this);
    }

    checkAnswer(e) {
        new Audio(click).play();
        let { isAnswered } = this.props;
        //alert(this.props.noQst);
        if(!isAnswered) {
            let elem = e.currentTarget;
            let { correct,difficulty, increaseScore,decreaseScore,countRightAnswer,descreaseRightAnswer } = this.props;
            let answer = Number(elem.dataset.id);
            //alert(answer);
            this.setState({
                preAnswer:answer
            })

            let updatedClassNames = this.props.classNames;
            updatedClassNames[this.state.preAnswer-1] = '';
            //alert(answer + correct);
            if(answer == correct){
                updatedClassNames[answer - 1] = 'right';
                if(this.props.noQst == this.state.setQstNo && this.state.setAnsType == 'right'){

                } else {
                    this.setState({
                        setAnsType:'right',
                        setQstNo:this.props.noQst
                    })
                    switch (difficulty) {
                        case 'l1':
                            this.props.increaseScore(5);
                            this.props.countRightAnswer();
                            break;
                        case 'l2':
                            this.props.increaseScore(10);
                            this.props.countRightAnswer();
                            break;
                        case 'l3':
                            this.props.increaseScore(50);
                            this.props.countRightAnswer();
                            break;
                        default:
                            this.props.increaseScore(5);
                            this.props.countRightAnswer();
                    }
                }

            }
            else {
                updatedClassNames[answer - 1] = 'wrong';

                if(this.state.setAnsType == 'wrong'){

                } else {
                    this.setState({
                        setAnsType: 'wrong',
                        setQstNo:this.props.noQst
                    })
                    if(this.props.noQst == this.state.setQstNo) {
                        switch (difficulty) {
                            case 'l1':
                                this.props.decreaseScore(5);
                                this.props.descreaseRightAnswer();
                                break;
                            case 'l2':
                                this.props.decreaseScore(10);
                                this.props.descreaseRightAnswer();
                                break;
                            case 'l3':
                                this.props.decreaseScore(50);
                                this.props.descreaseRightAnswer();
                                break;
                            default:
                                this.props.decreaseScore(5);
                                this.props.descreaseRightAnswer();
                        }
                    }
                }
            }

            // this.setState({
            //     classNames: updatedClassNames,
            //
            // })

            this.props.showButton();
            var myTime = setTimeout(() => {
                //this.clearClasses();
                //console.log("IN SET Timeout")
            }, 5000);
        }
    }
    clearClasses(){
        this.setState({
            classNames: ['', '', '', '']
        })

    }
    render() {
        let { answers } = this.props;
        let { classNames } = this.props;

        let transition = {
            transitionName: "example",
            transitionEnterTimeout: 500,
            transitionLeaveTimeout: 300
        }

        return (
            <div>
                { !this.props.timeOut ?
                    <div id="answers">
                        <ul>
                            <li onClick={this.checkAnswer}
                                className={classNames[0]} data-id="1">
                                {/*<span><input type="radio" className="duronto_radio"/></span>*/}
                                <p>{answers[0]}</p></li>

                            <li onClick={this.checkAnswer}
                                className={classNames[1]} data-id="2">
                                {/*<span><input type="radio" className="duronto_radio"/></span>*/}
                                <p>{answers[1]}</p></li>

                            <li onClick={this.checkAnswer}
                                className={classNames[2]} data-id="3">
                                {/*<span><input type="radio" className="duronto_radio"/></span>*/}
                                <p>{answers[2]}</p></li>

                            <li onClick={this.checkAnswer}
                                className={classNames[3]} data-id="4">
                                {/*<span><input type="radio" className="duronto_radio"/></span>*/}
                                <p>{answers[3]}</p></li>
                        </ul>
                    </div>:


                    <div className="timeOut">
                        <img src={unhappyImg} className="img-fluid" alt="Logo"/>
                        <span>Time Out</span>
                    </div>
                }
            </div>
        );
    }
}

export default Answers
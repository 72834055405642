import React, {useEffect, useState} from 'react';

const BlogFunctionalComponent = () => {
    const [resData,setResData] = useState('');
    const [count, setCount] = useState(0);
    const [click, setClick] = useState([{id:1,value: 'peyas'},{id:2,value: 'peyas2'}]);
    const [data, setData] = useState({
        username: '',
        password: ''
    });
    const [form, setForm] = useState({
        username: '',
        password: ''
    });

    const printValues = e => {
        e.preventDefault();
        setForm({
            username: data.username,
            password: data.password
        });
        alert(JSON.stringify(form));
    };

    const updateField = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    useEffect(() => {
        setResData("Hello")
        console.log("Mounting...");
    });

    const addNumber = () => {
      alert("working");
    }

    return (
        <div>
            {resData}
            <p>blog_functional_component</p>
            <ul>
                {click.map(item => (
                    <li key={item.id}>{item.value}</li>
                ))}
            </ul>
            {/*click increase*/}
                <p>You clicked {count} times</p>
                <button onClick={() => setCount(count + 1)}>
                    Click me
                </button>
            {/*end_click_increase*/}

            <button onClick={addNumber}>
                Click me
            </button>

            {/*  form_submit   */}
            <form onSubmit={printValues}>
                <label>
                    Username:
                    <input
                        value={data.username}
                        name="username"
                        onChange={updateField}
                    />
                </label>
                <br />
                <label>
                    Password:
                    <input
                        value={data.password}
                        name="password"
                        type="password"
                        onChange={updateField}
                    />
                </label>
                <br />
                <button>Submit</button>
            </form>
            <p>{form.username}</p>
            <p>{form.password}</p>
            {/*  end_form_submit   */}

        </div>
    );
};

export default BlogFunctionalComponent;